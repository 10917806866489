export function dashCaseToCamelCase(value) {
  return value.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isLineClamped = paragraph => {
  if (!paragraph) return false;
  return paragraph.scrollHeight > paragraph.clientHeight;
};
