import { WIDGET_API_EVENTS } from '../utils/constants';

// Map WIDGET_API_EVENTS into key-value pairs from key-object pairs.
const eventNames = (events = WIDGET_API_EVENTS) => {
  const keys = Object.keys(events);
  return keys.reduce((acc, key) => {
    if (!events[key].hasOwnProperty('name')) {
      return { ...acc, [key]: eventNames(events[key]) };
    }
    return { ...acc, [key]: events[key].name };
  }, {});
};

// Only expose the event names.
const EVENT = eventNames();

/**
 * Map WIDGET_API_EVENTS into flattened key-value pairs by name
 * from key-object pairs. This makes for easier lookups by name.
 * Example events object:
 * const WIDGET_API_EVENTS = {
 *   EVENT_GROUP: {
 *     EVENT_SUBGROUP: {
 *       name: 'event_subgroup',
 *       description: 'An event subgroup'
 *     },
 *     ANOTHER_EVENT_SUBGROUP: {
 *       name: 'another_event_subgroup',
 *       description: 'Another event subgroup'
 *     }
 *   },
 *   ANOTHER_EVENT_GROUP: {
 *     name: 'another_event_group',
 *     description: 'Another event group'
 *   }
 * };
 * Example flattened events object:
 * {
 *   'event_subgroup': {
 *     name: 'event_subgroup',
 *     description: 'An event subgroup'
 *   },
 *   'another_event_subgroup': {
 *     name: 'another_event_subgroup',
 *     description: 'Another event subgroup'
 *   },
 *   'another_event_group': {
 *     name: 'another_event_group',
 *     description: 'Another event group'
 *   }
 * }
 * @param {*} events
 * @returns
 */
const flattenEvents = (events = WIDGET_API_EVENTS) => {
  const keys = Object.keys(events);
  return keys.reduce((acc, key) => {
    if (!events[key].hasOwnProperty('name')) {
      return { ...acc, ...flattenEvents(events[key]) };
    }
    return { ...acc, [events[key].name]: events[key] };
  }, {});
};

// Here we want to flatten the events object into a single
// level object with the event name as the key so that we can
// easily look up the event by name without having to traverse
// the object.
const flattenedEvents = flattenEvents();
/**
 *
 * @param {*} eventName
 * @param {*} events
 * @returns true if the event is a public event, false otherwise.
 */
export const isPublicEvent = (eventName, events = EVENT) => {
  const keys = Object.keys(events);
  return keys.length && keys.some(key =>
    (typeof events[key] === 'object')
      ? isPublicEvent(eventName, events[key])
      : events[key] === eventName,
  );
};

/**
 *
 * @param {*} eventName
 * @param {*} callback
 * @throws Error if the callback is not a function or the event is not a public event.
 */
const validateAndExists = (eventName, callback) => {
  if (typeof callback !== 'function') {
    throw new Error(`Callback for event ${eventName} is not a function`);
  }
  if (!isPublicEvent(eventName)) {
    throw new Error(`Event ${eventName} is not a public event`);
  }
};

/**
 * Maintain map of widget APIs.
 */
const widgetApis = new Map();

/**
 *
 * @param {*} id
 * @returns a new widget API object.
 */
const createWidgetApi = (id) => {
  const listeners = new Map();
  const prevEvents = new Map();
  let config = null;
  let trackingTags = [];

  return {
    id,
    on: (eventName, callback) => {
      validateAndExists(eventName, callback);
      if (!listeners.has(eventName)) {
        listeners.set(eventName, []);
      }
      listeners.get(eventName).push(callback);
    },
    off: (eventName, callback) => {
      validateAndExists(eventName, callback);
      if (!listeners.has(eventName)) {
        return;
      }
      if (listeners.size === 1) {
        listeners.delete(eventName);
        return;
      }
      listeners.set(
        eventName,
        listeners.get(eventName).filter(cb => cb !== callback),
      );
    },
    removeAllListeners: () => listeners.clear(),
    emit: (eventName, data, state, widgetFrame) => {
      // Check valid event
      if (!isPublicEvent(eventName)) {
        return;
      }
      // Check constraints (such as only emit once)
      if (typeof flattenedEvents[eventName].constraint === 'function' &&
          flattenedEvents[eventName].constraint(prevEvents.get(eventName), { data, state }) !== true
      ) {
        // update previous event data
        prevEvents.set(eventName, { data, state });
        return;
      }
      // Perform transformation
      const publicData = flattenedEvents[eventName].hasOwnProperty('transformer')
        ? flattenedEvents[eventName].transformer(data, state, widgetFrame)
        : data;

      if (listeners.has(eventName)) {
        listeners.get(eventName).forEach(callback => callback(publicData));
      }
      // Always save previous event data since we may not have listeners yet
      prevEvents.set(eventName, { data, state });
    },
    configure(value) {
      if (this.config) {
        throw new Error('Configuration is already set');
      }
      // Only allow configuration with either fab or channel props
      if (value && (!value.hasOwnProperty('fab') && !value.hasOwnProperty('channel'))) {
        throw new Error('Configuration must have either fab or channel props');
      }
      config = value;
      if (!value.trackingTags) return;
      this.setTrackingTags(value.trackingTags);
    },
    setTrackingTags(tags = []) {
      if (!Array.isArray(tags)) {
        throw new Error('Tracking tags must be an array of key value pairs');
      }
      if (tags.length > 20) {
        console.warn('Bambuser limits number of tracking tags to 20. Excess tags will be ignored');
        tags = tags.slice(0, 20);
      }
      // Validate tags, remove invalid ones and duplicates
      trackingTags = Array.from(
        tags.reduce((map, tag) => {
          const { key, value } = tag;
          if (!key || typeof key !== 'string') {
            console.warn('Bambuser rejected tracking tag with invalid or missing key', tag);
            return map;
          }
          // Only allow string, number or boolean values
          if (typeof value !== 'string' && typeof value !== 'number' && typeof value !== 'boolean') {
            console.warn('Bambuser rejected tracking tag with invalid or missing value', tag);
            return map;
          }
          if (typeof value === 'string' && new Blob([value]).size > 1024) {
            console.warn('Bambuser rejected tracking tag exceeding allowed size limit of 1KB', tag);
            return map;
          }
          // Make sure there are no duplicates
          return map.set(key, {
            key,
            value,
          });
        }, new Map()).values(),
      );
    },
    get config() {
      return config;
    },
    get trackingTags() {
      return trackingTags;
    },
    get public() {
      return {
        id,
        EVENT,
        on: this.on,
        off: this.off,
        removeAllListeners: this.removeAllListeners,
        configure: this.configure,
        setTrackingTags: this.setTrackingTags,
      };
    },
  };
};

/**
 * Creates and stores a widget API for the given widget ID.
 * @param {string} id - Widget ID
 * @returns {object} The existing or new widget API object.
 */
export default (id) => {
  if (!widgetApis.has(id)) {
    widgetApis.set(id, createWidgetApi(id));
  }
  return widgetApis.get(id);
};
