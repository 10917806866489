import { generateHumanReadableShowIdSlug } from './misc';

export function getHtmlForShowRendering(show, full = false) {
  const rootElem = document.createElement(full ? 'div' : 'a');
  const showHumanReadableIdentifier = generateHumanReadableShowIdSlug(show);
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.set('featuredShow', showHumanReadableIdentifier);
  const url = `${window.location.origin}${window.location.pathname}?${queryParams.toString()}`;
  if (!full) rootElem.href = url;
  rootElem.id = showHumanReadableIdentifier;

  const showTitle = show.title || 'Live video shopping show';

  if (show.image) {
    const imgElem = document.createElement('img');
    imgElem.src = show.image;
    imgElem.alt = showTitle;
    rootElem.appendChild(imgElem);
  }

  const titleElem = document.createElement(full ? 'h2' : 'h3');
  titleElem.innerHTML = showTitle;
  rootElem.appendChild(titleElem);

  if (full && show.description) {
    const descriptionElem = document.createElement('p');
    descriptionElem.innerHTML = show.description;
    rootElem.appendChild(descriptionElem);
  }

  if (full && (show.startedAt || show.scheduledStartAt)) {
    const date = new Date(show.startedAt || show.scheduledStartAt);
    const dateElem = document.createElement('p');
    dateElem.innerHTML = `${date.toDateString()}, ${date.toTimeString()}`;
    rootElem.appendChild(dateElem);
  }

  return rootElem;
}

export function getShowDataMetaTag(show) {
  // <meta name="description" content="Description text">
  const metaElem = document.createElement('meta');
  metaElem.name = 'description';
  const showTitle = show.title || 'Live video shopping show';
  metaElem.content = `${showTitle}${show.description ? `, ${show.description}` : ''}`;
  metaElem.id = `lvs-meta-${show.showId}`;
  return metaElem;
}
