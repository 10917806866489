export const isSmallScreen = window.innerWidth <= 680;

export const isTabletScreen = window.innerWidth > 680 && window.innerWidth <= 1024;

export function isTouchDevice() {
  return (('ontouchstart' in window)
      || (navigator.MaxTouchPoints > 0)
      || (navigator.msMaxTouchPoints > 0));
}

export const canCopyToClipboard =
  typeof document.queryCommandSupported !== 'undefined' &&
  typeof document.execCommand !== 'undefined' &&
  document.queryCommandSupported('copy');

export const copyToClipboard = (url) => {
  if (!canCopyToClipboard) return false;
  const elem = document.createElement('input');
  elem.value = url;
  elem.setAttribute('readonly', '');
  elem.setSelectionRange(0, 99999); /* For mobile devices */
  document.body.appendChild(elem);
  elem.select();
  document.execCommand('copy');
};

export function isSafari() {
  return (navigator.vendor && navigator.vendor.indexOf('Apple') > -1
    && navigator.userAgent
    && navigator.userAgent.indexOf('CriOS') === -1
    && navigator.userAgent.indexOf('FxiOS') === -1);
}

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function registerElementSizeChangeListener(elem, callback) {
  const currentSize = { width: 0, height: 0 };
  const onSizeChange = (size) => {
    const newSize = {
      width: size.width,
      height: size.height,
    };
    const floatingWidget = elem.querySelector('#floating-widget');
    if (floatingWidget) {
      const floatWidgetStyles = window.getComputedStyle(floatingWidget);
      newSize.width = parseFloat(floatWidgetStyles.getPropertyValue('width'));
      newSize.height = parseFloat(floatWidgetStyles.getPropertyValue('height'));
    }
    if (currentSize.width !== newSize.width || currentSize.height !== newSize.height) {
      currentSize.width = newSize.width;
      currentSize.height = newSize.height;
      callback({
        width: Math.ceil(currentSize.width),
        height: Math.ceil(currentSize.height),
      });
    }
  };

  if (!isSafari() && window.ResizeObserver) {
    const resizeObserver = new ResizeObserver(entries => {
      const rect = entries[0].contentRect;
      onSizeChange(rect);
    });
    resizeObserver.observe(elem);
  } else if (window.MutationObserver) {
    const observer = new MutationObserver(mutations => {
      const rect = elem.getBoundingClientRect();
      onSizeChange(rect);

      // Ensure we detect images that may load and change the element size afterwards.
      const images = document.querySelectorAll('img');
      for (const image of images) {
        if (!image.complete) {
          image.addEventListener('load', () => {
            const rect = elem.getBoundingClientRect();
            onSizeChange(rect);
          });
        }
      }
    });
    const config = { attributes: true, childList: true, subtree: true };
    observer.observe(elem, config);
    window.addEventListener('resize', () => {
      const rect = elem.getBoundingClientRect();
      onSizeChange(rect);
    });
  }
}

export function loadScript(url, id) {
  return new Promise((resolve, reject) => {
    if (id) {
      const existingScript = document.querySelector(`script[id=${id}]`);
      if (existingScript) {
        if (existingScript.getAttribute('data-loaded') !== '1') {
          return existingScript.addEventListener('load', resolve);
        }

        return resolve();
      }
    }

    const js = document.createElement('script');
    if (id) {
      js.id = id;
    }
    js.type = 'text/javascript';
    js.async = 1;
    js.onload = () => {
      js.setAttribute('data-loaded', '1');
      resolve();
    };
    js.onerror = () => {
      document.body.removeChild(js);
      reject();
    };
    js.src = url;
    document.body.appendChild(js);
  });
}

export function preferContentNatively() {
  const { userAgent } = window.navigator;
  const robots = new RegExp([
    /bot/,/spider/,/crawl/, // GENERAL TERMS
    /APIs-Google/,/AdsBot/,/Googlebot/, // GOOGLE ROBOTS
    // TODO: At some point all these useragents make sense to treat differently
    // but at this time I don't understand all these useragents.
    /*/mediapartners/,/Google Favicon/,
    /FeedFetcher/,/Google-Read-Aloud/,
    /DuplexWeb-Google/,/googleweblight/,
    /bing/,/yandex/,/baidu/,/duckduck/,/yahoo/, // OTHER ENGINES
    /ecosia/,/ia_archiver/,
    /facebook/,/instagram/,/pinterest/,/reddit/, // SOCIAL MEDIA
    /slack/,/twitter/,/whatsapp/,/youtube/,
    /semrush/, // OTHER*/
  ].map((r) => r.source).join('|'), 'i'); // BUILD REGEXP + "i" FLAG

  return robots.test(userAgent);
}
