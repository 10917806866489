import Cookie from 'js-cookie';

/**
 * Read cookie
 * @param {string} name
 */
export const readCookie = (name) => Cookie.get(name);

/**
 * Write cookie
 * @param {string} name
 * @param {any} value
 * @param {any} options
 */
export const writeCookie = (name, value, options) => {
  try {
    Cookie.set(name, value, options);
  } catch (e) {
    if (process.env.NODE_ENV === 'development') {
      console.log(`Unable to set ${name} cookie with value ${value}`, e);
    }
  }
};
