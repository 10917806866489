/**
 * Read storage
 * @param {string} name
 */
export const readStorage = key => sessionStorage.getItem(key);

/**
 * Write storage
 * @param {string} name
 * @param {any} value
 */
export const writeStorage = (key, value) => {
  try {
    sessionStorage.setItem(key, value);
  } catch (e) {
    if (process.env.NODE_ENV === 'development') {
      console.log(`Unable to set ${key} sessionStorage with value ${value}`, e);
    }
  }
};

/**
 * Delete storage
 * @param {*} key
 */
export const deleteStorage = key => {
  try {
    sessionStorage.removeItem(key);
  } catch (e) {
    if (process.env.NODE_ENV === 'development') {
      console.log(`Unable to delete ${key} sessionStorage`, e);
    }
  }
};
