import qs from 'query-string';
import { dashCaseToCamelCase } from './utils/text';
import { STORAGE, FLOATING_WIDGET_STATE, COOKIES, WIDGET_EVENT_PREFIX, WIDGET_EVENTS } from './utils/constants';
import { readStorage, writeStorage } from './utils/storage';
import { inIframe, preferContentNatively } from './utils/browser';
import config from '../../client-config';
import { readCookie, writeCookie } from './utils/cookies';
import { v4 as uuidv4 } from 'uuid';
import { getFloatingWidgetPositions, getShowsForChannel, parseHumanReadableShowIdSlug } from './utils/misc';
import { getHtmlForShowRendering, getShowDataMetaTag } from './utils/shows';
import widgetApis, { isPublicEvent } from './api/widgetApi';

const widgetsBaseUrl = process.env.WIDGETS_BASE_URL;
const embedBaseUrl = process.env.EMBED_BASE_URL;
const widgets = new Map();
// Whoever embedded this has explicitly said that we're not allowed to use first party cookies.
const firstPartyCookiesDisabled =
  window.hasOwnProperty('_bamls_widgets_allowFirstPartyCookies') &&
  window._bamls_widgets_allowFirstPartyCookies === false;

// Instruct the embed to add the "credentialless" attribute on all iframes
// See "credentialless" here https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe
// More info: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Cross-Origin-Embedder-Policy
const credentiallessIframeEnabled =
  window.hasOwnProperty('_bamls_widgets_credentiallessIframe') &&
  window._bamls_widgets_credentiallessIframe === true;

function getUserId() {
  const userId = readCookie(COOKIES.USER_ID);
  if (userId) return userId;

  if (!firstPartyCookiesDisabled) {
    // We're allowed to store first party cookies, lets create a new user id.
    const newUserId = uuidv4();
    writeCookie(COOKIES.USER_ID, newUserId, { expires: 365 });
    return newUserId;
  }

  // We did not find the user id cookie, this is most likely due to
  // first party cookie disabled by the merchant. Lets use a less
  // persistent uid value for this user so the very least the tracking works.
  if (window._bamls_widgets_userId) return window._bamls_widgets_userId;

  const lessPersistentUserId = uuidv4();
  window._bamls_widgets_userId = lessPersistentUserId;
  return lessPersistentUserId;
}

function getSessionId() {
  const sessionId = readCookie(COOKIES.SESSION_ID);
  if (sessionId) {
    // Update session id cookie to continue it's existance.
    const inThirtyMinutes = new Date(new Date().getTime() + 30 * 60 * 1000);
    writeCookie(COOKIES.SESSION_ID, sessionId, { expires: inThirtyMinutes, secure: true });
  }
  if (sessionId) return sessionId;

  if (!firstPartyCookiesDisabled) {
    // We're allowed to store first party cookies, lets create a new session id.
    const newSessionId = uuidv4();
    const inThirtyMinutes = new Date(new Date().getTime() + 30 * 60 * 1000);
    writeCookie(COOKIES.SESSION_ID, newSessionId, { expires: inThirtyMinutes, secure: true });
    return newSessionId;
  }

  // We did not find the session id cookie, this is most likely due to
  // first party cookie disabled by the merchant. Lets use a less
  // persistent session id value for this user so the very least the tracking works.
  if (window._bamls_widgets_sessionId) return window._bamls_widgets_sessionId;

  const lessPersistentSessionId = uuidv4();
  window._bamls_widgets_sessionId = lessPersistentSessionId;
  return lessPersistentSessionId;
}

// Lets us set up floating widget if all criteria are met.
function embedFloatingWidget() {
  if (!prequisitsForShowingFloatingWidget()) return;
  const widgetId = window.__bfwId;
  if (!widgetId) return;

  // Remove all prior floating widgets
  const floatingWidgets = document.querySelectorAll('[data-bambuser-liveshopping-floating-id]');
  floatingWidgets.forEach((fw) => fw.remove());

  // Create new container for floating widget
  const widget = document.createElement('div');
  widget.style = 'display: none; position: fixed; z-index: 2147483647; margin-bottom: -4px;';
  widget.setAttribute('data-widget-id', widgetId);
  widget.setAttribute('data-bambuser-liveshopping-floating-id', widgetId);
  widget.setAttribute('data-bambuser-liveshopping-floating-condensed', readStorage(FLOATING_WIDGET_STATE.CONDENSED));
  if (window.__bfwApiUrl) widget.setAttribute('data-backend-base-url', window.__bfwApiUrl);
  if (window.__bfwl) widget.setAttribute('data-channel-locale', window.__bfwl);
  const uid = getUserId();
  if (uid) widget.setAttribute('data-bambuser-liveshopping-uid', uid);
  document.body.appendChild(widget);
  embedWidget(widget, 'floating');

  // Start listening to updates from player.
  // In case player is triggered else where on site we want to act accordingly.
  window.addEventListener('bambuser-liveshop-tracking-point', onLiveShopTrackingPoint);
}

/*
 * Global trigger for setting up floating widget
 * This comes in handy if embed code is already loaded(for example channels widget is available on site)
 * and the floating widget embed code comes in at a later stage
 * (perhaps due to tag manager doesn't load script immediately).
 * Then we have this global function which we can then invoke the code with.
 */
window.__bfwInit = embedFloatingWidget;

function getAllWidgetElements() {
  return document.querySelectorAll('[data-bambuser-liveshopping-widget]');
}

function parseArgs(elem) {
  const args = {};
  const attributeNames = elem.getAttributeNames();
  for (const name of attributeNames) {
    if (!name.startsWith('data-') || name === 'data-bambuser-liveshopping-widget') continue;
    const camelCasedName = dashCaseToCamelCase(name.substring('data-'.length));
    args[camelCasedName] = elem.getAttribute(name);
  }
  return args;
}

function getWidgetIframeTitle(widgetType) {
  let titleAttribute;

  switch (widgetType) {
    case 'channel':
      titleAttribute = 'Video library';
      break;
    case 'floating':
      titleAttribute = 'Small video preview of a live video';
      break;
    default:
      titleAttribute = '';
  }

  return titleAttribute;
}

function createIframe(widgetType, args = {}) {
  const widgetId = uuidv4();

  const iframe = document.createElement('iframe');
  iframe.setAttribute('src', getWidgetIFrameUrl(widgetId, widgetType, args));
  iframe.setAttribute('title', getWidgetIframeTitle(widgetType));
  iframe.setAttribute('frameborder', 0);
  iframe.setAttribute('allowTransparency', true);
  iframe.setAttribute('scrolling', 'no');
  iframe.setAttribute('data-bambusershouldplay', false);
  iframe.setAttribute('allow', 'web-share');
  iframe.setAttribute('data-test-id', 'iframeWidgets');
  if (credentiallessIframeEnabled) iframe.setAttribute('credentialless', true);
  iframe.style.width = '100%';
  iframe.style.height = '1px';
  iframe.style.display = 'flex';

  widgets.set(widgetId, iframe);

  return { iframe, widgetId };
}

function getWidgetIFrameUrl(widgetId, widgetType, params) {
  params = {
    ...params,
    name: widgetType,
    id: widgetId,
  };

  let iframeUrl = `${widgetsBaseUrl}/widget.html?${qs.stringify(params)}`;

  const queryParams = [];
  const pushTruthyParam = (name, value, encode = false, forcedValue) => {
    if (!value) return;
    if (forcedValue) value = forcedValue;
    queryParams.push(`${name}=${encode ? encodeURIComponent(value) : value}`);
  };

  if (widgetType === 'channel') {
    pushTruthyParam('sourceUrl', window.location.href, true); // Only used to detect instagram for escape (internal)

    const { featuredShow, autoplayLiveShopping } = qs.parse(location.search);
    if (featuredShow) pushTruthyParam('featured', featuredShow, true);
    if (autoplayLiveShopping) pushTruthyParam('requestedAutoplayShow', autoplayLiveShopping, true);
  }

  const { debugBambuser } = qs.parse(location.search);
  if (debugBambuser) pushTruthyParam('debugBambuser', debugBambuser, true);

  if (queryParams.length > 0) iframeUrl += `&${queryParams.join('&')}`;
  return iframeUrl;
}

function embedWidget(elem, type) {
  const preferInjectedContent =
    preferContentNatively() &&
    (elem.getAttribute('data-bambuser-liveshopping-widget') === 'channel' || type === 'channel');

  if (!elem.querySelector('iframe') && !preferInjectedContent) {
    const widgetType = elem.getAttribute('data-bambuser-liveshopping-widget') || type;
    if (!widgetType) return;
    const args = parseArgs(elem);
    const { iframe, widgetId } = createIframe(widgetType, args);
    elem.setAttribute('data-bambuser-liveshopping-widget-id', `${widgetId}`);
    elem.appendChild(iframe);
  } else if (preferInjectedContent) {
    injectNativeSEOHtml(elem);
  }
}

function injectNativeSEOHtml(elem) {
  // Lets try and render SEO friendly links and elements
  const channelId = elem.getAttribute('data-channel-id');
  const { widgetsAppEngineAPIBaseUrl, widgetsAPIBaseUrl } = config;
  if ((!widgetsAppEngineAPIBaseUrl && !widgetsAPIBaseUrl) || !channelId) return;

  const channelDataUrl = widgetsAppEngineAPIBaseUrl ?
    `${widgetsAppEngineAPIBaseUrl}/channels/${channelId}` :
    `${widgetsAPIBaseUrl}/channel/${channelId}`;

  fetch(channelDataUrl)
    .then((response) => response.json())
    .then((data) => {
      const { featuredShow } = qs.parse(location.search);
      const featuredShowId = parseHumanReadableShowIdSlug(featuredShow);
      const channelShows = getShowsForChannel(data);
      // TODO How do we use data from these shows as other SEO aspects?

      const featuredShowData = featuredShow ? channelShows.filter((show) => featuredShowId === show.showId)[0] : null;
      if (featuredShowData && !document.getElementById(`lvs-meta-${featuredShowData.showId}`)) {
        document.head.appendChild(getShowDataMetaTag(featuredShowData));
      }

      const showsElem = channelShows
        .filter((show) => show.showId !== featuredShowId)
        .map((show) => getHtmlForShowRendering(show));
      if (featuredShowData) showsElem.unshift(getHtmlForShowRendering(featuredShowData, true));
      showsElem.forEach((showElem) => {
        if (document.getElementById(showElem.id)) return; // don't render duplicate content/links to same show if available in multiple channels
        elem.appendChild(showElem);
      });
    })
    .catch((error) => {
      console.log('Failed to fetch channel data', error);
    });
}

// Criteria to present floating widget
function prequisitsForShowingFloatingWidget() {
  // Check if we a have a floating widget ID available
  const hasFloatingWidgetId = !!window.__bfwId;

  // The user have not dismissed the floating widget in this session
  const hasNotDismissedFloatWidgetSession = !readStorage(STORAGE.DISMISSED_FLOATING_WIDGET);

  // There's currently no liveshopping player in the window active
  const windowHasNoLiveShoppingPlayer = !document.querySelectorAll('[data-bambuser-liveshopping-player-id]').length;

  // This embed code is not run inside an iframe, which would happend if this got loaded in a mini player "surf behind" iframe
  const isInIframe = inIframe();
  return hasFloatingWidgetId && hasNotDismissedFloatWidgetSession && windowHasNoLiveShoppingPlayer && !isInIframe;
}

function embedWidgets(includeFloating = true) {
  // Wait for the DOM to be ready before we start embedding widgets
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => embedWidgets(includeFloating));
  } else {
    for (const elem of getAllWidgetElements()) {
      embedWidget(elem);
    }
    if (includeFloating) embedFloatingWidget();
    setupObserver();
  }
}

function getElemOffsetTop(elem) {
  let offsetTop = 0;
  do {
    if (!isNaN(elem.offsetTop)) {
      offsetTop += elem.offsetTop;
    }
  } while ((elem = elem.offsetParent));
  return offsetTop;
}

// Parse LiveShopping player tracking events to know when
// 1. The player is closed and we want to show the floating widget again
// 2. The player is loaded which in turns mean that we want to remove current floating widget
function onLiveShopTrackingPoint(event) {
  const { detail: { event: trackingEvent, data } = {} } = event;
  if (data && data.interactionType === 'close') {
    window.removeEventListener('bambuser-liveshop-tracking-point', onLiveShopTrackingPoint);
    embedFloatingWidget();
  } else if (trackingEvent === 'on-load') {
    // hide all floating widgets for sanity sake
    const floatingWidgets = document.querySelectorAll('[data-bambuser-liveshopping-floating-id]');
    floatingWidgets.forEach((fw) => fw.remove());
  }
}

function trackMetric(data) {
  const userId = getUserId();
  const sessionId = getSessionId();

  // No user or session ids, abort
  if (!userId || !sessionId) return;

  // No data to send
  if (!data) return;
  const { id, ...trackingData } = data;
  if (id) {
    const widgetApi = widgetApis(id);
    if (widgetApi?.trackingTags?.length > 0) {
      trackingData.customerTags = widgetApi.trackingTags;
    }
  }
  const compiledData = {
    source: 'widget',
    userId,
    sessionId,
    userDevice: window.navigator.userAgent,
    referrerUrl: window.location.origin,
    ...trackingData,
  };

  const dataString = JSON.stringify(compiledData);
  const endpoint = `${config.metricsBaseUrl}/metric`;
  let sendBeaconSuccessful = false;

  if (navigator && navigator.sendBeacon) {
    try {
      sendBeaconSuccessful = navigator.sendBeacon(endpoint, dataString);
    } catch (e) {
      console.log('Error sending metrics', e);
    }
  }

  // TODO: Activate fallback to sendBeacon tracking
  /*if (!sendBeaconSuccessful) {
    const img = document.createElement('img');
    img.src = url; // TODO: translate dataString to url params
    img.style.display = 'none';
    document.body.appendChild(img);
    img.onload = img.remove;
    img.onerror = img.remove;
  }*/
}

/*
 * In the case that an embed code is loaded _after_ the first embed code have
 * been loaded it's possible that the initiation function trigger is not called
 * because the embed code is returning early due to avoid loading multiple
 * embed codes on the same page.
 * To still allow channels/fabs being added/trigger _after_ the initial load of
 * the embed code we start to listen and see if our embed code is added again, and
 * if it's added again we trigger the initiation function again to set up the
 * components again.
 */
let _hasSetupObserver = false;
function setupObserver() {
  if (_hasSetupObserver) return;
  _hasSetupObserver = true;
  const observerConfig = { attributes: false, childList: true, subtree: true };
  new MutationObserver(function (mutationsList, observer) {
    for (let i = 0; i < mutationsList.length; i++) {
      const mutation = mutationsList[i];
      if (mutation.type === 'childList' && mutation.addedNodes.length) {
        mutation.addedNodes.forEach((node) => {
          if (
            node &&
            typeof node.innerHTML === 'string' &&
            node.innerHTML.includes('data-bambuser-liveshopping-widget') &&
            node.innerHTML.includes('data-channel-id')
          )
            embedWidgets(false); // Because we only check for new _channels_ we don't have to re-embed FABs
        });
      }
    }
  }).observe(document.body, observerConfig);
}

window.addEventListener('scroll', checkShouldPlay);

function checkShouldPlay(event) {
  for (const elem of getAllWidgetElements()) {
    // Get the iframe from widget element
    const iframe = elem.children[0];
    if (iframe && iframe.contentWindow) {
      const shouldPlay = JSON.parse(iframe.dataset.bambusershouldplay);
      // Calculate(and estimate) if the iframe element is within the viewport based on it's current location
      const elemOffsetTop = getElemOffsetTop(elem);
      const reachedElement = window.scrollY + window.innerHeight > elemOffsetTop;
      const scrolledPastElement = window.scrollY + window.innerHeight > elemOffsetTop + window.innerHeight * 2;
      // Check if we're within the viewport and if we've received check-play event from the channels widget to
      // determine if the widget should be allowed to play or not.
      const widgetId = elem.getAttribute('data-bambuser-liveshopping-widget-id');
      if (
        reachedElement &&
        !scrolledPastElement &&
        window._receivedBambuserWidgetCheckPlay &&
        window._receivedBambuserWidgetCheckPlay[widgetId]
      ) {
        // Check if current window has a LVS player available before allowing playback of preview
        // TODO: Check the top most window instead of current windows document to make a proper assumption
        const windowHasNoLiveShoppingPlayer = !document.querySelectorAll('[data-bambuser-liveshopping-player-id]')
          .length;
        // Check if current window is the top most window in the stack before allowing playback of preview
        /*
         * TODO: Allow preview playback if channel is presented in iframe as well - we need to signal to parent
         * top most window and see if that window has a active player instead of basing any assumptions on
         * `windowHasNoLiveShoppingPlayer` & `isWindowTop`.
         */
        const isWindowTop = window.self === window.top;
        if (!shouldPlay && windowHasNoLiveShoppingPlayer && isWindowTop) {
          // Set data-attribute which says which state we last sent to the widget in order to stop spammy calls
          iframe.setAttribute('data-bambusershouldplay', true);
          const payload = JSON.stringify({
            eventName: `${WIDGET_EVENT_PREFIX}${WIDGET_EVENTS.SHOULD_PLAY}`,
            data: { 'should-play': true },
          });
          iframe.contentWindow.postMessage(payload, '*');
        }
      } else if (shouldPlay) {
        // Set data-attribute which says which state we last sent to the widget in order to stop spammy calls
        iframe.setAttribute('data-bambusershouldplay', false);
        const payload = JSON.stringify({
          eventName: `${WIDGET_EVENT_PREFIX}${WIDGET_EVENTS.SHOULD_PLAY}`,
          data: { 'should-play': false },
        });
        iframe.contentWindow.postMessage(payload, '*');
      }
    }
  }
}

window.addEventListener('message', function (event) {
  const urlParams = new URLSearchParams(window.location.search);
  const isDev = process.env.NODE_ENV === 'development';
  const showDebug = (isDev && urlParams.get('debug')) || urlParams.get('debug-prod');

  if (!event.data || event.data[0] !== '{') return;

  // React to some events from the player
  if (event.data.startsWith('{"eventName":"livecommerce:')) {
    // If the full player is opened we want to pause all widgets
    if (event.data.startsWith('{"eventName":"livecommerce:ready"')) {
      for (const [key, widget] of widgets) {
        // Only touch channel embeds
        if (!widget?.parentNode?.getAttribute('data-bambuser-liveshopping-widget') === 'channel') continue;
        widget.setAttribute('data-bambusershouldplay', false);
        const payload = JSON.stringify({
          eventName: `${WIDGET_EVENT_PREFIX}${WIDGET_EVENTS.SHOULD_PLAY}`,
          data: { 'should-play': false },
        });
        widget.contentWindow?.postMessage(payload, '*');
      }
    }

    if (event.data.startsWith('{"eventName":"livecommerce:close"')) {
      // Wait a second for the player to tear itself down before we re-enable the widgets
      // If we don't wait, checkShouldPlay() will find an element matching [data-bambuser-liveshopping-player-id].
      setTimeout(() => {
        checkShouldPlay();
      }, 1000);
    }
  }

  let isAcceptedOrigin = false;
  for (const [key, widget] of widgets) {
    if (widget.contentWindow === event.source) {
      isAcceptedOrigin = true;
      break;
    }
  }
  if (!isAcceptedOrigin) {
    // The message came from an unapproved origin
    return;
  }

  let message = null;
  try {
    message = JSON.parse(event.data);
  } catch (e) {
    console.log(e, message);
  }

  if (!message || !message.eventName || !message.eventName.startsWith(WIDGET_EVENT_PREFIX)) return;

  if (showDebug) {
    console.log('Received event from widget', event);
  }

  const widgetApi = message.data.id && widgetApis(message.data.id);

  const injectScriptTag = (urlPath, callback) => {
    const scriptNode = document.createElement('script');
    scriptNode['src'] = `${urlPath}/embed.js`;
    scriptNode.setAttribute('bam-lvs-embed-src', '');

    const onload = () => {
      if (typeof callback === 'function') callback();
    };

    scriptNode.onerror = () => {
      // In case our first attempt to load the script node we try again with a
      // slightly modified url which holds another pattern, which should not
      // yield a 404.
      scriptNode.remove();
      const urlParts = urlPath.split('/');
      const customization = urlParts.pop();
      urlParts.push('default');
      const baseUrl = `${urlParts.join('/')}`;
      const fallbackScriptNode = document.createElement('script');
      fallbackScriptNode['src'] = `${baseUrl}/embed.js?customization=${customization}`;
      fallbackScriptNode.setAttribute('bam-lvs-embed-src', '');
      fallbackScriptNode.onload = onload;
      document.body.appendChild(fallbackScriptNode);
    };

    scriptNode.onload = onload;

    document.body.appendChild(scriptNode);
  };

  const clearPreviousEmbedCodesIfNew = (newEmbedUrl) => {
    // If we have a previously embedded player embed code in the DOM injected
    // from this widgets embed code we'll want to remove the old one before
    // adding the new one to avoid two different 1:M embeds to exist on the same
    // page. This should ensure that we have the latest requested shows embed code
    // as the embed code which is used.
    const previousScriptTags = document.querySelectorAll('[bam-lvs-embed-src]');
    if (previousScriptTags.length) {
      for (const previousScriptTag of previousScriptTags) {
        if (!previousScriptTag['src'].includes(newEmbedUrl)) {
          previousScriptTag.remove();
          delete window.initBambuserLiveShopping;
        }
      }
    }
  };

  if (message.eventName === `${WIDGET_EVENT_PREFIX}${WIDGET_EVENTS.SIZE}`) {
    const widgetFrame = widgets.get(message.data.id);
    if (widgetFrame && widgetFrame.contentWindow === event.source) {
      widgetFrame.style.height = `${message.data.height}px`;
      if (message.data.forceWidth) {
        widgetFrame.style.width = `${message.data.width}px`;
      }
    }
  } else if (message.eventName === `${WIDGET_EVENT_PREFIX}${WIDGET_EVENTS.TRACKING}`) {
    const data = message.data;
    trackMetric(data);
  } else if (message.eventName === `${WIDGET_EVENT_PREFIX}${WIDGET_EVENTS.PLAY}`) {
    if (message.data.hideWidget) {
      if (message.data.shouldCondense) {
        // Click comes from floating widget, next time it's presented it should be in condensed mode
        writeStorage(FLOATING_WIDGET_STATE.CONDENSED, true);
      }

      // If the click is coming from floating widget we remove the widget.
      const widgetFrame = widgets.get(message.data.id);
      const iframeParent = widgetFrame.parentNode;
      if (iframeParent)
        setTimeout(function () {
          iframeParent.remove();
        }, 250);
    }

    const initiatePlayer = () => {
      const player = new window.BambuserLiveShopping({
        eventId: message.data.showId,
        type: 'overlay',
      });
      player.show();
    };

    clearPreviousEmbedCodesIfNew(message.data.embedBaseUrl);

    if (!window.initBambuserLiveShopping) {
      window.initBambuserLiveShopping = function (item) {
        window.initBambuserLiveShopping.queue.push(item);
      };
      window.initBambuserLiveShopping.queue = [];
      injectScriptTag(message.data.embedBaseUrl || embedBaseUrl, initiatePlayer);
    } else {
      initiatePlayer();
    }
  } else if (message.eventName === `${WIDGET_EVENT_PREFIX}${WIDGET_EVENTS.CHECK_PLAY}`) {
    if (!window._receivedBambuserWidgetCheckPlay) window._receivedBambuserWidgetCheckPlay = {};
    window._receivedBambuserWidgetCheckPlay[message.data.widgetId] = true;
    checkShouldPlay();
  } else if (message.eventName === `${WIDGET_EVENT_PREFIX}${WIDGET_EVENTS.FLOATING.REPOSITION}`) {
    // Reposition floating widget according to data provided from the iframe
    const newPosition = message.data.position;

    const offsetsX = window.__bfwOffsX ? window.__bfwOffsX : [8, 16];
    const offsetsY = window.__bfwOffsY ? window.__bfwOffsY : [8, 16];
    const floating_widget_positions = getFloatingWidgetPositions(offsetsX, offsetsY);
    const position = floating_widget_positions[newPosition] || floating_widget_positions['Bottom right'];
    const widgetFrame = widgets.get(message.data.id);
    const iframeParent = widgetFrame?.parentNode;
    if (iframeParent) {
      iframeParent.style.top = position.top;
      iframeParent.style.right = position.right;
      iframeParent.style.bottom = position.bottom;
      iframeParent.style.left = position.left;
      iframeParent['data-offsets-x'] = floating_widget_positions.offsetX;
      iframeParent['data-offsets-y'] = floating_widget_positions.offsetY;
    }
  } else if (message.eventName === `${WIDGET_EVENT_PREFIX}${WIDGET_EVENTS.FLOATING.PRESENT}`) {
    // Toggle if floating widget should be presented or not
    const presentWidget = message.data.present || false;
    const widgetFrame = widgets.get(message.data.id);
    const iframeParent = widgetFrame?.parentNode;
    if (iframeParent) iframeParent.style.display = presentWidget ? 'inline-block' : 'none';

    const bodyBamPlaylists = document.body.querySelectorAll('bam-playlist');
    const bodyBamFabPlaylists = Array.from(bodyBamPlaylists).filter((bp) => bp._attr?.mode === 'fab');
    if (bodyBamFabPlaylists.length) {
      if (presentWidget) {
        // Remove any bam-playlists with mode=fab which is placed in root of body
        bodyBamFabPlaylists.forEach((bp) => {
          // Make sure they're all contracted before removing them to avoid potentional left over z-indexes
          // which might be applied by the bam-playlist being in expanded mode.
          if (typeof bp._contract === 'function') bp._contract();
          // Allow triggered call stack to finish before removing the element
          setTimeout(() => bp.remove());
        });
      } else if (typeof window.__bSetupBamPlaylistFabResolver === 'function') {
        // If we have a callback function to set up bam-playlist fab we call it now that we know
        // that the floating widget is not in displayable state.
        window.__bSetupBamPlaylistFabResolver();
      }
    }
    window.__bFabHasInitiallyLoaded = true;
  } else if (message.eventName === `${WIDGET_EVENT_PREFIX}${WIDGET_EVENTS.FLOATING.DISMISS}`) {
    // Add to sessionStorage that floating widget should be considered dismissed
    writeStorage(STORAGE.DISMISSED_FLOATING_WIDGET, true);
    const widgetFrame = widgets.get(message.data.id);
    const iframeParent = widgetFrame?.parentNode;
    if (iframeParent) iframeParent.remove();
  } else if (message.eventName === `${WIDGET_EVENT_PREFIX}${WIDGET_EVENTS.FLOATING.CONDENSE}`) {
    // Add to sessionStorage that floating widget should be presented in condensed mode
    writeStorage(FLOATING_WIDGET_STATE.CONDENSED, true);
  } else if (message.eventName === `${WIDGET_EVENT_PREFIX}${WIDGET_EVENTS.FLOATING.NAVIGATE}`) {
    const { showId, redirection: { target = '_self', url } } = message.data;
    const queryParamSeparator = url.indexOf('?') > -1 ? '&' : '?';
    const locationHref = `${url}${queryParamSeparator}autoplayLiveShopping=${showId}`;
    window.open(locationHref, target);
  } else if (message.eventName === `${WIDGET_EVENT_PREFIX}${WIDGET_EVENTS.EMBED_CODE}`) {
    clearPreviousEmbedCodesIfNew(message.data.embedBaseUrl);
    if (!window.initBambuserLiveShopping) {
      window.initBambuserLiveShopping = function (item) {
        window.initBambuserLiveShopping.queue.push(item);
      };
      window.initBambuserLiveShopping.queue = [];
      injectScriptTag(message.data.embedBaseUrl || embedBaseUrl);
    }
  } else if (message.eventName === `${WIDGET_EVENT_PREFIX}${WIDGET_EVENTS.READY}`) {
    window.onBambuserWidgetReady && window.onBambuserWidgetReady(widgetApi.public);

    // Notify any code running on embedding site that a new instance is ready so it knowns when instance methods can to
    // be called, ex. configure or setTrackingTags from GTM scripts.
    const instanceReadyEvent = new CustomEvent('bambuser-instance-ready', {
      detail: {
        type: 'widget',
        instanceKey: `widget-${message.data.id}`,
        instance: widgetApi.public,
      },
    });
    document.dispatchEvent(instanceReadyEvent);

    // Post the configuration to the widget
    const widgetFrame = widgets.get(message.data.id);
    const eventName = `${WIDGET_EVENT_PREFIX}${WIDGET_EVENTS.CONFIGURE}`;
    widgetFrame?.contentWindow.postMessage(JSON.stringify({
      eventName,
      data: {
        id: message.data.id,
        config: widgetApi.config,
      },
    }), '*');
  }

  // Always emit if the eventName is prefixed with liveshopping-widget with
  // the prefix removed.
  const apiEventName = message.eventName.replace(WIDGET_EVENT_PREFIX, '');
  if (isPublicEvent(apiEventName) && widgetApi) {
    widgetApi.emit(apiEventName, message.data, message.state, widgets.get(message.data.id));
    showDebug && console.log('Emitting event to widget API:', apiEventName, message.data);
  }
});

embedWidgets();
