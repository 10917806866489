/**
 * Decide which test group id belongs to
 * @param {string} id
 */
export const decideABTestGroup = (id) => {
  if (!id) return null;
  const uuidValues = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];
  const firstPosChar = id.substring(0, 1);
  if (uuidValues.indexOf(firstPosChar) === -1) return null;
  return uuidValues.indexOf(firstPosChar) >= (uuidValues.length / 2) ? 1 : 0;
};

const isNumber = (val) => {
  return Number.isInteger(val);
};

const validOffsets = (offsets) => {
  if (!Array.isArray(offsets) || offsets.length !== 2) {
    return false;
  }

  if (!isNumber(offsets[0]) || !isNumber(offsets[1])) {
    return false;
  }
  return true;
};

export const getFloatingWidgetPositions = (offsetsX = [8, 16], offsetsY = [8, 16]) => {
  const offsetX = validOffsets(offsetsX) ? window.innerWidth < 480 ? offsetsX[0] : offsetsX[1] : window.innerWidth < 480 ? 8 : 16;
  const offsetY = validOffsets(offsetsY) ? window.innerWidth < 480 ? offsetsY[0] : offsetsY[1] : window.innerWidth < 480 ? 8 : 16;
  const offsetXToUse = `${offsetX}px`;
  const offsetYToUse = `${offsetY}px`;

  return {
    'Top left': { top: offsetYToUse, left: offsetXToUse, bottom: 'initial', right: 'initial' },
    'Top right': { top: offsetYToUse, left: 'initial', bottom: 'initial', right: offsetXToUse },
    'Bottom right': { top: 'initial', left: 'initial', bottom: offsetYToUse, right: offsetXToUse },
    'Bottom left': { top: 'initial', left: offsetXToUse, bottom: offsetYToUse, right: 'initial' },
    offsetX,
    offsetY,
  };
};

export const getLanguage = ({ locale }) => {
  if (!locale && locale.length < 2) return;
  return locale.split('-')[0];
};

export const getShowsForChannel = (channelData) => {
  const shows = [];
  const { playlists } = channelData;
  if (playlists) {
    playlists.forEach(playlist => {
      if (playlist.shows) playlist.shows.forEach(show => shows.push(show));
    });
  }
  return shows;
};

export const generateHumanReadableShowIdSlug = (show) => {
  const showTitle = show.title || 'untitled-show';
  return `${showTitle.toLowerCase()}-${show.showId}`.replaceAll(/[^a-zA-Z0-9/]+/ig, '-');
};

export const parseHumanReadableShowIdSlug = (string) => {
  return string ? decodeURIComponent(string).split('-').pop() : null;
};

export const FetchTimeout = (timeInSecs) => {
  const controller = new AbortController();
  setTimeout(() => controller.abort(), timeInSecs * 1000);
  return controller;
};

export const isObject = value => {
  return value && typeof value === 'object' && value.constructor === Object;
};
